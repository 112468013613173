import "./App.css";
import { createBrowserRouter, Outlet, RouterProvider, useLocation } from "react-router-dom"
import SwapPage from "./Swap"
import HomePage from "./Home"
import About from "./About";




export function detectMob() {
    return window.innerWidth <= 800 && window.innerHeight <= 1000;
}


const router = createBrowserRouter([
    {
        path: "",
        element: <><Outlet /></>,
        errorElement: <>error</>,
        children: [
            {
                path: "/",
                index: true,
                element: <HomePage />,
            },
            // {
            //     path: "/swap",
            //     element: <SwapPage />,
            // },
            // {
            //     path: "/about",
            //     element: <About />,
            // },
        ],
    },

])

export const AppRoutes = () => {

    return (
        <RouterProvider
            router={router}
        />
    )
}




const EthApp = () => {
    return (
        <>
            <AppRoutes />
        </>
    );
};

export default EthApp;
